<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import OnboardingForm from "@/components/onboardingForm";
// import { required } from "vuelidate/lib/validators";
import { paymentMethods } from "@/state/helpers";
import * as cloneDeep from "lodash.clonedeep";
import {
  FETCH_PAYMENT_ONBOARDING,
  ONBOARDING_STATUS_CHECK
} from "@/state/actions.type";

export default {
  components: {
    Layout,
    PageHeader,
    OnboardingForm,
  },

  data() {
    var queryParams = new URLSearchParams(window.location.search)
    var channelName = queryParams.get("channelName")
    return {
      paymentChannelCreds: null,
      channelName:{channelName},
      status: ""
    };
  },

  computed: {
    paymentChannelCredsList() {
      return this.$store.state.paymentChannels.onboardingDetailsEntry;
    },

    chaiPayKey() {
      return this.$store.getters["auth/iamportKey"];
    },
    items() {
      return [
        {
          text: this.$t("menuitems.integration.list.payconfig"),
        },
        {
          text: this.$t("menuitems.integration.list.pay-channels"),
          active: true,
        },
      ];
    },

    title() {
      return this.$t("menuitems.integration.list.pay-channels");
      // return this.$t("menuitems.integration.list.pay-channelstwo");
    },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },
  },

  created() {
    if(this.channelName.channelName === 'STRIPE') {
      this.$store.dispatch(`paymentChannels/${ONBOARDING_STATUS_CHECK}`, {
        chaiPayKey: this.chaiPayKey,
        psp: this.$route.query.channelName.toLowerCase()
      }).then((status) => {
        if(status.status === "Pending") {
          this.status = "pending";
          if(!status.detailsSubmitted) {
            this.$notify({
              type: "error",
              text: `Stripe Account details are not submitted`,
              closeOnClick: true,
            });
          } else if(!status.chargesEnabled) {
            this.$notify({
              type: "error",
              text: `Stripe charges are not enabled`,
              closeOnClick: true,
            });
          }
        }
      });
    } else {
      this.fetchPaymentOnboarding();
    }
  },

  watch: {
    paymentChannelCredsList() {
      this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    },
  },

  mounted() {
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    if(this.channelName.channelName === 'PAYPAL') {
      document.body.classList.add("dynamic-body");
      (function(d, s, id) {
        var js, ref = d.getElementsByTagName(s)[0];
        if (!d.getElementById(id)) {
          js = d.createElement(s);
          js.id = id;
          js.async = true;
          js.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
          ref.parentNode.insertBefore(js, ref);
        }
      }(document, "script", "paypal-js"));
    }
  },

  destroyed() {
    if(this.channelName.channelName === 'PAYPAL') {
      document.body.classList.remove("dynamic-body");
    }
  },

  methods: {
    ...paymentMethods,
    fetchPaymentOnboarding(redirect = false) {
      let merchant_key = this.$route.query.key;
      this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_ONBOARDING}`, {
        channelName: this.$route.query.channelName,
        payType: "PAYIN",
        merchant_key:merchant_key
      }).then((res) => {
        if(redirect) {
          window.location.href = res.redirection
        }
      });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div v-if="channelName.channelName === 'PAYPAL'" class="">
      <div class="pt-0">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12 col-sm-12">
              <div class="card border checkout-preview-card">
                <div class="card-body" style="padding: 0px;">
                  <section>
                    <p>An instant global business.</p>
                    <p>One integration for all your online payment needs.</p>
                    <h5>Benefits</h5>
                    <ul>
                      <li>PayPal Checkout provides better conversion through a streamlined web and mobile checkout experience.</li>
                      <li>Simplified onboarding for seller to accept international payments.</li>
                      <li>Access to 400M+ PayPal customers around the globel, with local currency support for better payment management.</li>
                      <li>Peace of mind for you and your customers with buyer and seller protection on eligible sales, and tokenized payments for an additional layer of security.</li>
                      <li>A robust, 360-degree payments solution with domestic & international card processing, PayPal, and alternative payment methods.</li>
                      <li>With fraud monitoring you can sell with confidence</li>
                    </ul>
                    <p>*PayPal Second Quarter 2021 Results</p>
                  </section>
                   <button class="btn btn-primary d-inline-block">
                      <a style="color: #fff;" data-paypal-button="true" v-bind:href="paymentChannelCredsList.redirection+'&displayMode=minibrowser'" target="PPFrame">Sign up for PayPal</a>
                    </button>
                    <div class="chaipay-container" id="chaipay-container" style="height: 100%;width: 100%;">
                        <iframe style="opacity: 1; height: 100%; position: relative; background: none; display: block; border: 0px none transparent; margin: 0px; padding: 0px; z-index: 2; width: 100%;" allowtransparency="true" frameborder="0" width="100%" height="100%" allowpaymentrequest="true"
                        src="" id="PPFrame" class="chaipay-checkout-frame"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>

    <div v-else-if="channelName.channelName === 'STRIPE'" class="">
      <div class="pt-0">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12 col-sm-12">
              <div class="card border checkout-preview-card">
                <div class="card-body" style="padding: 0px;">
                  <section>
                    <p>An instant global business.</p>
                    <p>One integration for all your online payment needs.</p>
                    <h5>Benefits</h5>
                    <ul>
                      <li>Close to the metal: From direct integrations with card networks and banks to checkout flows in the browser, Stripe operate on and optimize at every level of the financial stack.</li>
                      <li>Fastest-improving platform: Stripe release hundreds of features and improvements each year to help you stay ahead of industry shifts. (On average, Stripe deploy production API 16x per day.)</li>
                      <li>Battle-tested reliability: Stripe systems operate with 99.99%+ uptime and are highly scalable and redundant. Stripe is certified to the highest compliance standards.</li>
                      <li>Intelligent optimizations: Stripe machine learning models train on billions of data points and help increase revenue across conversion, fraud, revenue recovery, and more.</li>
                    </ul>
                  </section>
                  <button @click="fetchPaymentOnboarding(true)" class="btn btn-primary d-inline-block">{{ status === 'pending' ? 'Continue Sign up for Stripe' : 'Sign up for Stripe' }}</button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div v-else style="display: flex; align-items: center; justify-content:center">
      <div class="col-md-8 rounded px-5 py-4 shadow bg-white text-left" style="background: white; ">
        <OnboardingForm :channelName="channelName" />
      </div>
    </div>
  </Layout>
</template>

<style scoped>
input:focus:required:invalid {
  color: green;
}

::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.card-body-custom{
  padding:10px;
  cursor: pointer;
}
.media-body-custom{
  border-top: 1px solid #DCE8FC;
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0px;
  top: 10px;
}
.avatar-background{
    background-image: url("../../../../assets/images/dots.png");
    background-size: 60px;
    background-position-x: 25%;
    background-position-y: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0px;
}
@media (min-width: 1550px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
@media (max-width: 575px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
.dynamic-card{
  width: 88%;
  margin: auto;
  margin-bottom: 24px;
}
::v-deep .nav-tabs > li > a{
  color: #B3AFAF;
}
::v-deep .nav-tabs-custom .nav-item .nav-link.active{
  color:#252B3B;
}
::v-deep .nav-tabs-custom .nav-item .nav-link::after{
  background:#252B3B;
}
.channel-separator{
  border-bottom: 1px solid #D9D9D9;
  margin-bottom:24px;
}
@media (max-width: 575px) {
  ::v-deep .page-title-box{
    display: block !important;
    padding-bottom: 10px;
  }
  ::v-deep .page-title-box h4{
    margin-bottom: 5px!important;
  }
}
.checkout-preview-card{
  background:none;
}
@media (min-width: 768px) {
  .checkout-preview-card{
    border: none !important;
    box-shadow: none;
  }
}
.filterToggle{
    float: right;
    border: 1px solid #EAEAEA;
    background: #fff;
    border-radius: 6px;
    padding: 7px 20px;
    margin-bottom: 20px;
}
.filter-section{
    box-shadow: 0px 3px 10px #0000001a;
    background: #fff;
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 380px;
    right: 15px;
}
.top-section{
    border-bottom: 1px solid #F0F0F0;
    padding: 15px;
    padding-left: 35px;
}
.saveFilterButton{
    display: inline-block;
    background: #252B3A;
    color: #fff;
    font-size: 13px;
    padding: 5px 25px;
    border-radius: 4px;
    cursor: pointer;
}
.clear_filter{
  color: #BFBFBF;
  display: inline-block;
  margin-left: 15px;
  font-size: 13px;
  cursor: pointer;
}
.bottom-section{
    padding: 15px;
    padding-left: 35px;
}
.filter_heading{
    color: #BFBFBF;
    padding-bottom: 15px;
    font-size: 13px;
}
.checkbox-group{
  position: relative;
}
.checkbox-group input{
  display: none;
}
.checkbox-group  label {
  cursor: pointer;
  font-size: 13px;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group input:checked+label:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0px 2px 2px 0px;
  transform: rotate(45deg);
}
.filter_channel,.filter_method{
  display: inline-block;
  vertical-align: top;
}
.filter_method{
    float: right;
    padding-right: 30px;
}
.lang-menu select {
    padding: 3px 10px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    color: #828282;
    font-size: 12px;
}
::v-deep .main-content{
      overflow: visible;
}
</style>